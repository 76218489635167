import React, { Fragment } from 'react';

import { Meta } from '@components/Meta';
import { OrderConfirmed } from '@components/OrderConfirmed';
import { Footer } from '@components/Footer';
import { Layout } from '@components/Layout';

export type OrderConfirmedProps = {
  siteText: SiteText[];
} & BasePageProps;

type Props = { pageContext: OrderConfirmedProps };

export default ({ pageContext: { siteText, homepageFonts } }: Props) => (
  <Fragment>
    <Meta title="Order Confirmed" />

    <Layout homepageFonts={homepageFonts}>
      <OrderConfirmed siteText={siteText} />
    </Layout>

    <Footer />
  </Fragment>
);
